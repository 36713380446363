import { combineReducers } from 'redux'
import { userInfo, userRoleData } from './userInfo'
import { 
    deptWithUserTreeData, 
    cityTreeData, 
    labelTreeData, 
    lqSearchConditionData, 
    taskObj, 
    messages, 
    messageNumber, 
    downLoadUrlMap, 
    sysNotFollowDay, 
    businessLineTree 
} from './common'

export default combineReducers({
    userInfo,
    userRoleData,
    deptWithUserTreeData,
    cityTreeData,
    businessLineTree,
    taskObj,
    messages,
    messageNumber,
    labelTreeData,
    lqSearchConditionData,
    downLoadUrlMap,
    sysNotFollowDay
})
