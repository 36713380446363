import { 
    queryDeptTreeData, 
    oaGetmyMsgNumber, 
    sysDictCitys, 
    sysLabelList, 
    lqSearchConditions, 
    getImportExcelConsumeOrderStatus, 
    customerGetMessage, 
    getDownLoadUrlMap,
    getSystemRuleNotFollowupDay,
    sysDictListRight,
    getTelecomArea
} from '@api/index'
import { 
    SET_COMMON_DEPTWITHUSERDATR, 
    SET_CITY_TREEDATA, 
    SET_LABEL_TREEDATA, 
    SET_LQ_SEARCHCONDITION, 
    SET_USER_PROCESS, 
    SET_USER_MESSAGES, 
    SET_USER_MESSAGE_NUMBER, 
    SET_DOWNLOAD_URLMAP, 
    SET_SYS_NOTFOLLOWDAY,
    SET_BUSINESSLINE_TREE
} from '../contants'
import { 
    BUSINESS_LINE,
    getCustomerReturnDesc,
    handleCommonTreeData,
    handleDeptWithUserTreeData, 
    transferTreeData 
} from '@utils/index'

// const getDictEnumApi = productApi(sysDictListRight)
export const setDeptWithUserTreeData = (deptWithUserData: any[]) => {
    return {
        type: SET_COMMON_DEPTWITHUSERDATR,
        deptWithUserData
    }
}

export const setCityTreeData = (cityTreeData: any[]) => {
    return {
        type: SET_CITY_TREEDATA,
        cityTreeData
    }
}

export const setBusinessLineTree = (businessLineTree: any[]) => {
    return {
        type: SET_BUSINESSLINE_TREE,
        businessLineTree
    }
}


export const setLabelTreeData = (labelTreeData: any[]) => {
    return {
        type: SET_LABEL_TREEDATA,
        labelTreeData
    }
}

export const setDownLoadUrlMap = (downLoadUrlMap: Record<string, string>) => {
    return {
        type: SET_DOWNLOAD_URLMAP,
        downLoadUrlMap
    }
}

export const setSysNotFollowDay = (sysNotFollowDay: Record<string, string>) => {
    return {
        type: SET_SYS_NOTFOLLOWDAY,
        sysNotFollowDay
    }
}

// 猎奇模块
// 猎奇搜索条件查询
export const setLqSearchConditionData = (lqSearchConditionData: any) => {
    return {
        type: SET_LQ_SEARCHCONDITION,
        lqSearchConditionData
    }
}

export type TaskObj = {
    state: boolean;
    processName: string;
    speedProgress: number;
}
// 获取当前正在后台执行（导出、导入）的文件list
export const setTaskObj = (taskObj: Record<string, TaskObj>) => {
    return {
        type: SET_USER_PROCESS,
        taskObj
    }
}

// 获取后端推送过来的消息队列
export const setMessages = (messages: any[]) => {
    return {
        type: SET_USER_MESSAGES,
        messages
    }
}

// 获取未读消息总数量
export const setMessageNumber = (messageNumber: number) => {
    return {
        type: SET_USER_MESSAGE_NUMBER,
        messageNumber
    }
}

// 异步设置组织架构树带人员 action
export const asyncSetDeptWithUserTreeData = () => {
    return async (dispatch: any) => {
        dispatch(setDeptWithUserTreeData([]))
        const { data } = await queryDeptTreeData({type: 2}) as any
        handleDeptWithUserTreeData(data||[])
        dispatch(setDeptWithUserTreeData(data))
    }
}

// 异步获取省市区数据
export const asyncSetCityTreeData = () => {
    return async (dispatch: any) => {
       dispatch(setCityTreeData([]))
       const { data } = await sysDictCitys({}) as any
       transferTreeData(data)
       dispatch(setCityTreeData(data))
    }
}

// 异步获取业务线树，【不合理之处】
export const asyncSetBusinessLineTree = () => {
    return async (dispatch: any) => {
       dispatch(setBusinessLineTree([]))
       const { data } = await sysDictListRight({pageSize: 1000, pageCurrent: 1, status: 1, dictId: 19 }) as any
       const options = data?.values?.map(({optionId: value, optionName: label}: any) => ({label, value})) || []
       const { data: aliyun } = await sysDictListRight({pageSize: 1000, pageCurrent: 1, status: 1, dictId: 289}) as any  // 阿里云业务线 子类
       const ailiyunOptions = aliyun?.values?.map(({optionId: value, optionName: label}: any) => ({label, value})) || []
       const { data: tiyiData } = await getTelecomArea() as any  // 天翼云业务线 子类
       const { newTreeData } = handleCommonTreeData(tiyiData, (item) => ({...item, label: item.name, value: item.id}))
       const tree = options?.map((option: any) => {
            // id 会变，通过 name 来判断
            if (option.label?.includes('阿里云')) {
                return {
                    ...option,
                    children: [{label: '请选择客户分层', value: -999999, disabled: true}].concat(ailiyunOptions)
                }
            } else if (option?.label?.includes('天翼云')) {
                return {
                    ...option,
                    children: [
                        {label: '请选择电信分局', value: -999998, disabled: true},
                        {label: '无', value: BUSINESS_LINE.TIANYI_OTHRT }
                    ].concat(newTreeData)
                }
            } else {
                return option
            }
        })
       dispatch(setBusinessLineTree(tree))
    }
}

// 异步获取客户标签树
export const asyncSetLabelTreeData = () => {
    return async (dispatch: any) => {
       dispatch(setLabelTreeData([]))
       const { data } = await sysLabelList({pageCurrent: 1, pageSize: 1000}) as any
       const { values } = data || {}
       transferTreeData(values||[])
       dispatch(setLabelTreeData(values||[]))
    }
}

// 猎奇搜索条件查询
export const asyncSetLqSearchConditionData = () => {
    return async (dispatch: any) => {
       dispatch(setLqSearchConditionData([]))
       const { data } = await lqSearchConditions({}) as any
       dispatch(setLqSearchConditionData(data||[]))
    }
}

// 获取当前正在后台执行（导出、导入）的文件list
export const asyncSetGetConsumeOrderProcess = () => {
    return async (dispatch: any) => {
        const { data } = await getImportExcelConsumeOrderStatus({}) as any
        dispatch(setTaskObj( data || {}))
    }
}

export const asyncSetMessageList = () => {
    return async (dispatch: any) => {
        const { data } = await customerGetMessage({}) as any
        const { list, msgNum } = data || {}
        dispatch(setMessages(list||{}))
        dispatch(setMessageNumber(msgNum||0))
        
    }
}

// 查询未读消息总数量
export const asyncSetMessageNumber = () => {
    return async (dispatch: any) => {
        const { data } = await oaGetmyMsgNumber() as any
        dispatch(setMessageNumber(data))
    }
}

// 设置模版下载url 地址
export const asyncSetDownLoadUrlMap = () => {
    return async (dispatch: any) => {
        const { data } = await getDownLoadUrlMap({}) as any
        dispatch(setDownLoadUrlMap( data || {}))
    }
}

// 获取系统中为跟进天数的设置
export const asyncSetSysNotFollowDay = () => {
    return async (dispatch: any) => {
        const { data } = await getSystemRuleNotFollowupDay({}) as any
        const desc = getCustomerReturnDesc(data)
        dispatch(setSysNotFollowDay( {...data, desc} ))
    }
}
