import React, { ReactNode, useEffect, useRef, useState } from 'react';
import ProForm, { ProFormText, ProFormSelect, ProFormTextArea, ProFormCascader, ProFormRadio, ProFormDependency, ProFormMoney, ProFormDatePicker, ProFormUploadButton, ProFormDateTimePicker, ModalForm } from '@ant-design/pro-form';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import { Button, Col, Form, Row, Tooltip, Image, message, Space, TreeSelect, Cascader, Table } from 'antd';
import { BackBan } from '@components/index';
import { useCantactsByCustomerIdHooks, useDeptDataWithRoleHooks, useDictEnumHooks, useProductTreeDataHooks } from '@hooks/index';
import { useHistory, useLocation } from 'react-router-dom';
import { getToken, handleUploadFiles, isImageFile, parseSearch, productApi, treeDataDisabledRules, validatorAccountAndType, validatorMuch2Single } from '@utils/util';
import { customerListByName, customerGetAccountsById, customerBusinessSave, customerBusinessUpdate, BASEURL } from '@api/index';
import { debounce } from 'lodash'
import { getBusDetailApi } from '../BusinessDetail';
import { handleHelperIds, HelperTagRender, resverHandleHelperIds } from '@components/Customer/List';
import moment from 'moment';
import { PreviewProps } from '@components/Common/Preview';
import { Icon, ProFormCustTreeSelect, ProFormTreeSelect, ProFormWithCache } from '@components/Common';
import { accountFieldNames, accountRequestApi } from '@components/EnterpriseDetail/cpnts/CustomerRebate';
import { bussinessSonTableColumns, bussinessSonTableItem, salesStageColumns, salesStageDataSource } from './data';
import { BUSINESS_LINE, custOptions, yesNoOptions } from '@utils/enum';
import { useSelector } from 'react-redux';
import { StateType } from '@store/index';
import { EditableProTable } from '@ant-design/pro-table';
import { uniq } from 'lodash';

type FormDataType = {
    businessOpportunityTitle: string; // 商机标题
    customerId: string;	    // 客户名称 主键
    isNewCustomer: number;  // 是否新客户
    contactsId: string;	    // 主要联系人 
    visit: number;          // 上门拜访
    chatGroup: string;      // 建立沟通群
    followBusinessLine: string;	  // 关注业务线
    opportunityPoint: string;   // 项目机会点
    productId?: string;	     // 意向产品
    accountIsRegister?: number; // 账号是否注册
    accountId?: string;	// 预估下单账号
    synergys?: string[];    // 协同人
    estimatedSalesAmount: string;  // 预计销售金额
    estimatedCashAmount: string;   // 预计Cash收入
    estimatedPriceDiffAmount: string; // 预计差价收入
    purchase: string;               // 采购方式
    pocTest: string;               // POC测试情况
    estimatedSigningDate: string;  // 预计签单日期
    salesStage: string;            // 销售阶段
    helps: string;                 // 求助点
    remarks: string;               // 备注
    files: string;	               // 附件
}

const customerListByNameApi = productApi(customerListByName)
const customerAccountsByIdApi = productApi(customerGetAccountsById)
const addBusinessApi = productApi(customerBusinessSave)
const updateBusinessApi = productApi(customerBusinessUpdate)


export const FileItemRender = (originNode: ReactNode, file: any, fileList: object[], actions: { download: Function, preview: Function, remove: Function }, onPreview: Function) => {
    const {name, format} = file || {}
    const { remove, preview } = actions
    const thatName = format ? `${name}.${format}` : name
    return <Row justify='space-between' className='jns-file-item'>
            <Col style={{display: 'inline-block', width: 'calc(100% - 50px)', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                <Icon type="icon-fujian" /><a>{thatName}</a>
            </Col>
            <Col>
                <Tooltip placement="top" title="预览"><Icon type="icon-icon_yulan" style={{marginRight: 10}} onClick={() => onPreview && onPreview(file)} /></Tooltip>
                <Tooltip placement="top" title="删除"><Icon type="icon-shanchu" onClick={() => remove() } /></Tooltip>
            </Col>
        </Row>
}

export const SalesStageToolTip = (props: ModalFormProps) => {
    return (<ModalForm title="项目阶段说明" submitter={false} {...props}>
        <Table
           columns={salesStageColumns}
           pagination={false}
           bordered
           dataSource={salesStageDataSource}
        />
    </ModalForm>)
}


const BussinessSonTable = ({form, dataSource: pDataSource, ...rest}: any) => {
    const [dataSource, setDataSource] = useState<any[]>(bussinessSonTableItem(0))
    const [accountsEditableKeys, setAccountsEditableKeys] = useState<React.Key[]>(() => dataSource.map((item) => item.id))
  
    // 实现所有 账号, 联系人 可编辑
    useEffect(() => {
      setAccountsEditableKeys(dataSource.map((item) => item.id))
    }, [dataSource])
  
    useEffect(() => {
        setDataSource([...pDataSource])
    }, [pDataSource])
  
    const columns = bussinessSonTableColumns(form)
  
    return (<EditableProTable<any>
            className="no-padding-pro-card-body"
            scroll={{x: 2000}}
            columns={columns}
            rowKey="id"
            value={dataSource} 
            // onChange={(dataSource) => {
            //     if (dataSource?.length < 2) {
            //         return message.warning('至少要有一条商机明细！')
            //     } else {
            //         setDataSource(dataSource)
            //     }
            // }} 
            recordCreatorProps={{
              newRecordType: 'dataSource',
              record: bussinessSonTableItem()[0],
            }}
            editable={{
              form,
              type: 'multiple',
              deleteText: null,
              deletePopconfirmMessage: '确认删除该条数据吗?',
              editableKeys: accountsEditableKeys,
              actionRender: (row, config, defaultDoms) => {
                // 原有的商机明细数据 不能让其删除
                return (row?.id + '').startsWith('zqs-') ? [defaultDoms.delete] : []
              },
              onValuesChange: async (_, editableRows) =>  {
                if (editableRows?.length < 1) {
                    return message.warning('至少要有一条商机明细！')
                } else {
                    setDataSource(editableRows)
                    // form.validateFields()
                }
              },
            }}
            {...rest}
        />
    )
}



export default () => {
    const { search } = useLocation()
    const history = useHistory()
    const { id, originId, customerId, customerName, fromPath,  } = parseSearch(search)
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    // const [treeData, , getUserRoleApi] = useDeptDataWithRoleHooks({}, true) // 协同人数据
    const pmTreeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)
    const [prodTreeData ] = useProductTreeDataHooks({}, true, (v: any) => { 
        v.id = v.whetherProduct ? `d${v.id}` : `p${v.id}`
        v._title = v.name
        v.key = v.id
        v.title = v.name
        v.value = v.id
        v.disabled = !v.whetherProduct
        return v
    })

    const [cantactsOptions, , getCantactsByCusIdApi] = useCantactsByCustomerIdHooks({}, false)
    // const [followBusinessLineOptions,] = useDictEnumHooks({dictId: 19}, true)
    // const [salesStageOptions,] = useDictEnumHooks({dictId: 20}, true)
    // const [chatGroupOptions,] = useDictEnumHooks({dictId: 266}, true)
    // const [visitOptions,] = useDictEnumHooks({dictId: 267}, true)
    // const [orderTypeOptions,] = useDictEnumHooks({dictId: 254}, true) // 订单类型
    // const [orderModeOptions,] = useDictEnumHooks({dictId: 279}, true)
    
    const [customerOptions, setCustomerOptions] = useState([])
    const [estimatedAccountOptions, setEstimatedAccountOptions] = useState([])
    const formRef = useRef<ProFormInstance>()
    const [sonFormRef] = Form.useForm();
    const [dataSource, setDataSource] = useState<any[]>(bussinessSonTableItem(1))

    const [previewProps, setPreviewProps] = useState<PreviewProps>({visible: false, src: undefined})

    const request = async (param: object) => {
        const { data } = await getBusDetailApi(param, false, )
        const { 
            businessOpportunityTitle, 
            customerId, 
            customerName, 
            primaryConcat: contactsId, 
            followBusinessLineId, 
            product, 
            accountType,
            accountId, 
            synergys: synergy, 
            estimatedSalesAmount, 
            estimatedSigningDate, 
            salesStage, 
            files, 
            remarks,
            whetherNewCustomer,
            whetherVisit,
            setUpGroup,
            opportunityPoint,
            accountIsNew,
            estimatedCashIncome,
            estimatedBetweenIncome,
            customerAccount,
            buyMethod,
            orderMethod,
            orderType,
            isChanceOfBeingAlone,
            isPocTest,
            substatements,
        } = data || {}

        // 处理 followBusinessLineId, customerHierarchy 字段
        const newsubstatements = substatements.map((v: any) => ({...v, followBusinessLineId: [...v.followBusinessLineId, ...(uniq(v.customerHierarchy||[])).map(Number)]}))
        console.log('==========>', newsubstatements)
        onSearchCustomer(customerName)
        setDataSource(newsubstatements)
        customerId && onValuesChange({customerId})

        return { 
            businessOpportunityTitle,
            customerId,
            customerName, 
            contactsId, 
            followBusinessLineId, 
            customerAccount: (customerAccount || []).map((v: any) => [`${v[0]}-type`, v[1]]),
            // synergys: resverHandleHelperIds(synergy||[], treeData||[]),
            synergys: synergy ? synergy.map((v: string) => `s${v}`) : [0],
            salesStage, 

            opportunityPoint,
            estimatedSigningDate, 
            estimatedCashIncome,
            estimatedBetweenIncome,
            productId: (product||[]).map((v: string | number)=> `d${v}`),
            buyMethod,
            orderMethod,
            orderType,
            accountIsNew: +accountIsNew,
            isChanceOfBeingAlone,
            isPocTest,
            setUpGroup
        }
    }

    const onValuesChange = async (values: any) => {
        if ('customerId' in values) {
            formRef.current?.setFieldsValue({contactsId: undefined})
            getCantactsByCusIdApi(values)
        }
    }
    const onSearchCustomer = async (customerName: string, ownedByMe: boolean = true) => {
        await customerListByNameApi({key: customerName, ownedByMe}, false, (data: any[]) => {
           const newOptions = (data||[]).map(({id: value, customerName: label}) => ({label, value}))
           setCustomerOptions(newOptions as any)
        })
    }
    const onPreview = (file: any) => {
        const { response } = file || {}
        const {name, format, url, } = response ? (response||{}).data : file
        isImageFile(`.${format}`) ? setPreviewProps({visible: true, src: url}) : message.warning('该文件暂不支持预览')
    }

    // scrollToFirstError 提交失败时候滚动到第一个错误
    const scrollToFirstError = () => {
        const errorFields = sonFormRef.getFieldsError()
        const { name } = errorFields.find(({ errors }: any) => errors?.length > 0) || {}
        name && sonFormRef.scrollToField(name, { behavior: 'smooth', block: 'center', inline: 'center' })
    }
    const onSubmit = async ({synergys: synergysArr, productId: pids, followBusinessLine, setUpGroup, accountIsNew, customerAccount, ...rest}: any) => {
        const newCustomerAccount = (customerAccount || []).filter((v: any) => !!v[1]).map((v: any) => [parseInt(v[0]), v[1]])
        const productId = (pids||[]).map((v: string) => (v||'').slice(1))
        const whichApi = id ? updateBusinessApi : addBusinessApi
        
        scrollToFirstError()
        const ok1 = await sonFormRef.validateFields()
        // scrollToField
        if (ok1) {
            const substatements = sonFormRef.getFieldsValue()
            const newSubstatements = Object.keys(substatements||{}).map(id => {
                const { estimatedSigningDate, actualSigningDate, followBusinessLineId: followBusinessLineIdArr, ...rest } = substatements[id]
                const [followBusinessLineId, ...customerHierarchy] = followBusinessLineIdArr
                if ((id + '').startsWith('zqs-')) {
                    return {
                        ...rest,
                        estimatedSigningDate: estimatedSigningDate ? moment(estimatedSigningDate)?.format('YYYY-MM-DD') : estimatedSigningDate,
                        actualSigningDate: actualSigningDate ? moment(actualSigningDate)?.format('YYYY-MM-DD') : actualSigningDate||'',
                        residualIntentionCashIncome: ((rest?.estimatedCashIncome||0) - (rest?.singleCashReceipts||0)).toFixed(4),
                        residualIntentionSpreadIncome: ((rest?.estimatedBetweenIncome||0) - (rest?.intentionSpreadIncome||0)).toFixed(4),
                        singleCashReceipts: rest.singleCashReceipts || 0,
                        intentionSpreadIncome: rest.intentionSpreadIncome || 0,
                        followBusinessLineId: [followBusinessLineId],
                        customerHierarchy: customerHierarchy.includes(BUSINESS_LINE.TIANYI_OTHRT) ? new Array(3).fill(BUSINESS_LINE.TIANYI_OTHRT) : customerHierarchy,
                    }
                } else {
                    return {
                        ...substatements[id], 
                        estimatedSigningDate: estimatedSigningDate ? moment(estimatedSigningDate)?.format('YYYY-MM-DD') : estimatedSigningDate,
                        actualSigningDate: actualSigningDate ? moment(actualSigningDate)?.format('YYYY-MM-DD') : actualSigningDate||'',
                        residualIntentionCashIncome: ((rest?.estimatedCashIncome||0) - (rest?.singleCashReceipts||0)).toFixed(4),
                        residualIntentionSpreadIncome: ((rest?.estimatedBetweenIncome||0) - (rest?.intentionSpreadIncome||0)).toFixed(4),
                        singleCashReceipts: rest.singleCashReceipts || 0,
                        intentionSpreadIncome: rest.intentionSpreadIncome || 0,
                        id,
                        followBusinessLineId: [followBusinessLineId],
                        customerHierarchy: customerHierarchy.includes(BUSINESS_LINE.TIANYI_OTHRT) ? new Array(3).fill(BUSINESS_LINE.TIANYI_OTHRT) : customerHierarchy,
                    }
                }
            })

            const params = {
                ...rest, 
                synergys: Array.isArray(synergysArr) ? synergysArr.filter(Boolean)?.map((id: string) => id.slice(1)) : [], 
                // files, 
                // productId, 
                businessOppId: id, 
                // accountIsNew,
                // customerAccount: newCustomerAccount,
                // followBusinessLine,
                // setUpGroup,
                substatements: newSubstatements
            }
    
            const { success } = await whichApi(params, true, () => {
                setTimeout(() => {
                    history.goBack()
                }, 1000)
            })
            return success
        }
    }

    const disabledDate = (current: any) => current && current < moment().subtract (1,"days").endOf('day')

    const calcDisabledCustomerId = () => ['/customer/list'].includes(fromPath)
    useEffect(() => {
       if (['/customer/list'].includes(fromPath)) {
            onSearchCustomer(customerName, false)
            customerId && onValuesChange({customerId})
            formRef.current?.setFieldsValue({customerId: customerId * 1})
       }
    }, [fromPath])
    
    return (
        <div>
            <BackBan title={id ? '编辑商机' : originId ? '一键复制商机' : '创建商机'} style={{paddingLeft: 20, position: 'sticky', top: -24, zIndex: 9}} />
            <div className="jns-add-customer-form" style={{background: '#fff', boxSizing: 'border-box', padding: '20px'}}>
                <ProFormWithCache<FormDataType>
                    key={location.href}
                    layout="horizontal"
                    labelCol={{span: 4}}
                    onFinish={onSubmit}
                    params={{businessOppId: id||originId, customerId}}
                    request={(id||originId) && request as any}
                    onValuesChange={onValuesChange}
                    formRef={formRef}
                    submitter={{render: (props, doms) => [
                        <div className="jns-fix-submit" key="submit">
                            <Button  type="primary" style={{marginLeft: 130}} onClick={() => props.form?.submit?.()}>提交</Button>
                        </div>,
                        // <Button key="rest" onClick={() => props.form?.resetFields?.()}>重置</Button>,
                    ]}}
                >
                    <ProFormText 
                        width="md" 
                        name="businessOpportunityTitle" 
                        fieldProps={{maxLength: 30}} 
                        label="商机标题" 
                        rules={[{ required: true, message: '请填写商机标题',}]} 
                        placeholder="请输入" 
                    />
                    <ProFormSelect 
                        width="md" 
                        name="customerId" 
                        disabled={calcDisabledCustomerId()} 
                        label="客户名称" 
                        options={customerOptions} 
                        fieldProps={{
                            showSearch: true, 
                            filterOption: false, 
                            onSearch: debounce(onSearchCustomer, 600)
                        }} 
                        rules={[{ required: true, message: '请选择关联客户',}]} 
                        placeholder="请输入客户名称查询" 
                    />
                    <ProFormText 
                        width="md" 
                        name="opportunityPoint" 
                        fieldProps={{maxLength: 100}} 
                        label="项目机会点" 
                        rules={[{ required: true, message: '请填写项目机会点',}]} 
                        placeholder="请填写" 
                    />
                    <ProFormCustTreeSelect
                        width="md" 
                        name="synergys" 
                        label="协同人(售前)" 
                        tooltip="请选择协同人，如，销售主管、售前等"
                        custOptions={custOptions}
                        fieldProps={{
                            treeData: pmTreeData, 
                            showSearch: true,
                            multiple: true, 
                            treeCheckable: true, 
                            treeNodeFilterProp: 'title', 
                            style: { width: 328 }
                        }} 
                        rules={[{required: true, message: '请选择协同人'}]} 
                        placeholder="请选择" 
                    />

                    <Form.Item label="商机明细" wrapperCol={{span: 24}} required style={{marginBottom: 200}}>
                        <BussinessSonTable form={sonFormRef}  dataSource={dataSource} />
                    </Form.Item>

                </ProFormWithCache>
                <Image style={{display: 'none'}} preview={{...previewProps, onVisibleChange: (visible: boolean) => setPreviewProps({...previewProps, visible})}}/>
        </div>
    </div>)
}
