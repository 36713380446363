import React, { ReactNode, useEffect, useMemo, useState } from "react";
import ProForm, { ProFormItemProps, ProFormProps } from '@ant-design/pro-form';
import ProTable, { ColumnsState, ProTableProps } from "@ant-design/pro-table";
import { AutoComplete, Avatar, Cascader, Input, Table, TreeSelect, Upload } from "antd";
import { ParamsType } from "@ant-design/pro-provider";
import { FormDragSetting, getFormDragSettingOptions, setColumnsByOptions } from "..";
import { cloneDeep } from 'lodash'
import { useFormDataCache } from "@hooks/index";
import { isFunction } from "lodash";
import { CustTreeSelect, CustTreeSelectProps } from "@components/Workbench";
interface AddproFormItemProps extends ProFormItemProps{
  onSelect?: any; //添加切换事件   切换部门  自动回填负责人
}
export interface ProFormCustTreeSelectProps extends  Omit<ProFormItemProps, 'placeholder'>, Omit<CustTreeSelectProps, 'status'>{
  custOptions?: CustTreeSelectProps['custOptions']
}

export const ProFormTreeSelect = ({fieldProps, ...rest}: AddproFormItemProps) => {
    return (
      <ProForm.Item {...rest}>
        <TreeSelect {...(fieldProps||{}) as any} {...rest} />
      </ProForm.Item>
    );
};

export const ProFormCustTreeSelect = ({ fieldProps, custOptions, ...restProps}: ProFormCustTreeSelectProps) => {
  return (<ProForm.Item {...restProps}>
      <CustTreeSelect custOptions={custOptions} {...fieldProps}/>
  </ProForm.Item>
  )
}


export const ProFormAutoComplete = ({fieldProps, ...rest}: AddproFormItemProps) => {
  return (
    <ProForm.Item {...rest}>
      <AutoComplete {...(fieldProps||{}) as any} {...rest} />
    </ProForm.Item>
  );
};

export const ProFormCascader = ({fieldProps, ...rest}: AddproFormItemProps) => {
  return (<ProForm.Item {...rest}>
              <Cascader {...fieldProps} />
          </ProForm.Item>)
}

export const ProFormAvatar = ({fieldProps, ...rest}: AddproFormItemProps) => {
  return (<ProForm.Item {...rest}>
    <Upload>
      <Avatar style={{cursor: 'pointer'}} />
    </Upload>
  </ProForm.Item>)
}

export const TableSummary = ({options, data}: any) => {
  return (<Table.Summary fixed>
        <Table.Summary.Row>
          {/* <Table.Summary.Cell key="总计" index={0}>总计</Table.Summary.Cell> */}
            {
              options?.filter((item: any) => item.show).map((v: any, idx: number) => <Table.Summary.Cell key={v.param} index={idx}>{ idx !== 0 ? data[v.param] : '总计'}</Table.Summary.Cell> )
            }
          </Table.Summary.Row>
      </Table.Summary>)
}

export const NumberInput = ({value, onChange, ...rest}: any) => {
  const onPaste = (event: any) => {
    event.preventDefault();
    const clipboardData = event.clipboardData
    const pastedData = clipboardData.getData('Text');
    const newPastedData = pastedData.replace(/,/g, '');
    onChange?.(newPastedData)
  }
  return <Input 
    placeholder="请输入"
    value={value}
    onPaste={onPaste}
    onChange={(e: any) => onChange?.(e.target.value)}
    {...rest}
  />
}


const getSummaryOptionsByColumns = (columns: any) => {
  const summaryOptions = columns.filter((column: any) => !(column.hideInTable === true)).map((v: any) => ({param: v.dataIndex, show: true}))
  return summaryOptions
}

interface ProTableWithFormSettingProps<T> extends ProTableProps<T,ParamsType>{
  settingOptions?: any[];
  searchState?: { persistenceKey: string, persistenceType: 'localStorage' | 'sessionStorage'}
}
// ProTable 搜索条件可拖拽排序
const useFormDragHooks = ({columns, searchState, request, ...rest}: ProTableWithFormSettingProps<any>) => {
  const { persistenceKey, persistenceType } = searchState || {}
  const columnsBack = cloneDeep(columns)
  
  const getInitSettingOptions = (columns: any[]) => {
    if ( persistenceType && persistenceKey ) {
      const settingOptionsStr = window[persistenceType].getItem(persistenceKey)
      const settingOptions = JSON.parse(settingOptionsStr as any) || getFormDragSettingOptions(columns as any)
      return settingOptions
    } else {
      return getFormDragSettingOptions(columns as any)
    }
  }

  const getInitColumns = (settingOptions: any) => {
    return setColumnsByOptions(settingOptions as any, columnsBack as any)
  }

  const initSettingOptions = getInitSettingOptions(columnsBack as any)
  const initColumns = getInitColumns(initSettingOptions)
  const [ownColumns, setOwnColumns] = useState(initColumns)
  const [settingOptions, setSettingOptions] = useState(initSettingOptions)

  const onFormSettingChange = (options: any[], type: string) => {
    if (type === 'reset') {
      const settingOptions = getFormDragSettingOptions(columns as any)
      setSettingOptions(settingOptions)
    } else {
      setSettingOptions(options as any)
    }
  }

  useEffect(() => {
    persistenceType && persistenceKey && window[persistenceType].setItem(persistenceKey, JSON.stringify(settingOptions))
    const newColumns = setColumnsByOptions(settingOptions as any, columnsBack as any)
    setOwnColumns([...newColumns] as any)
  }, [settingOptions, columns])

  return {
    ownColumns,
    settingOptions,
    onFormSettingChange,
  }
}

// ProTable 底部总结栏
const useProTabSummaryHooks = ({columns, searchState, request, ...rest}: ProTableWithFormSettingProps<any>) => {
  const [summaryOptions, setSummaryOptions] = useState([])
  const [summaryData, setSummaryData] = useState(null)
  const getSummaryOptions = (localState?: any) => {
    const { persistenceKey, persistenceType } = rest?.columnsState || {}
    if (localState) {
      return getSummaryOptionsByColumns(columns).map((v: any) => ({...v, ...localState[v.param]})).sort((a: any, b: any) => a.order - b.order)
    } 
    if (persistenceKey && persistenceType) {
      localState = JSON.parse((window[persistenceType] as any)?.getItem?.(persistenceKey))
      return getSummaryOptionsByColumns(columns).map((v: any) => ({...v, ...localState[v.param]})).sort((a: any, b: any) => a.order - b.order)
    }
    return getSummaryOptionsByColumns(columns)
  }
  useEffect(() => {
    const columnState = getSummaryOptions()
    setSummaryOptions(columnState)
  }, [columns])

  return {
    summaryOptions,
    setSummaryOptions,
    summaryData,
    setSummaryData,
    getSummaryOptions
  }
}

export const ProTableWithFormSetting: <T>(props: ProTableWithFormSettingProps<T>) => JSX.Element = (props) => {
  const {
    ownColumns,
    settingOptions,
    onFormSettingChange,
  } = useFormDragHooks(props)

  const {
    summaryOptions,
    setSummaryOptions,
    summaryData,
    setSummaryData,
    getSummaryOptions
  } = useProTabSummaryHooks(props) 

  const request = async (...args: any[]) => {
    const { summaryData, ...rest } = await props?.request?.call(null, ...args) as any
    setSummaryData(summaryData)
    return rest
  }

  const calcSummaryOptions = () => {
    const options: any = [{param: '总计', show: true}]
    // props.expandable && props.rowSelection && options.push({param: undefined, show: true})
    // return [...options, ...(summaryOptions||[])]
    if (props.expandable && props.rowSelection) {
      return [...options, {param: undefined, show: true}, ...(summaryOptions||[])]
    }
    if (props.expandable || props.rowSelection) {
      return [...options, ...(summaryOptions||[])]
    }
    return [...(summaryOptions||[])]
  }

  const ownProps = {
    summary: summaryData ? () => <TableSummary options={calcSummaryOptions()} data={summaryData||{}}  /> : undefined,
    ...props,
    request: props?.request ? request : undefined,
    columnsState: {
      ...props?.columnsState,
      onChange: (value: Record<string, ColumnsState>) => {
        const columnState = getSummaryOptions(value)
        setSummaryOptions(columnState)
      }
    }
  }

  return <ProTable 
            search={{
              labelWidth: 'auto',  // 甲方说 搜索文案太长 缩简不了 可以 牺牲美观
              optionRender: (searchConfig, formProps, dom) => [
                ...dom.reverse(),
                <FormDragSetting key="drag" options={settingOptions as any} onChange={onFormSettingChange} />,
              ],
            }}
            {...ownProps}
            columns={ownColumns as any}
          />
}

interface ProFormWithCacheProps<T> extends ProFormProps<T>{
  settingOptions?: any[];
  cacheKey?: string;
  searchState?: { persistenceKey: string, persistenceType: 'localStorage' | 'sessionStorage'}
}
export const ProFormWithCache: <T>(props: ProFormWithCacheProps<T>) => JSX.Element = ({children, cacheKey, request, onValuesChange, initialValues, onFinish, ...rest}: ProFormWithCacheProps<any>) => {
  const [getCache, setCache, removeCache] = useFormDataCache(cacheKey)
  const ownRequest = async (...args: any[]) => {
    if (getCache()) {
      return getCache()
    }
    if (request) {
      return request(...args)
    }
  }
  const onOwnValuesChange = (values: any, allValues: any) => {
    setCache(allValues)
    if (onValuesChange) {
      onValuesChange(values, allValues)
    }
  }
  const ownProps = {
    request: request ? ownRequest : null,
    onValuesChange: onOwnValuesChange,
    initialValues: initialValues || getCache(),
    onFinish: async (...args: any) => {
      const success = await onFinish?.call(null, ...args)
      success && removeCache()
    }
  }
  return (<ProForm
    {...ownProps}
    {...rest}
  >
    {children}
  </ProForm>)
}