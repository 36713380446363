import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'; // 异步中间件
import type { TaskObj } from './actions/common';
import reducer from './reducers'
export type StateType = {
    userInfo?: Record<string, any>; // 用户信息
    userRoleData?: Array<any>;
    deptWithUserTreeData?: Array<any>; // 组织架构树数据
    cityTreeData?: Array<any>; // 省市区数据
    labelTreeData?: Array<any>; // 标签数据
    lqSearchConditionData?: any; // 猎奇搜索条件
    taskObj?: Record<string, TaskObj>; //获取当前正在后台执行（导出、导入）的文件进度
    messages?: Record<string, any>; // 后端推送的消息队列
    messageNumber?: number; //未读消息总数
    downLoadUrlMap: Record<string, any>; // 模版地址 url map
    sysNotFollowDay?: Record<string, any>; // 获取系统中未跟进天数设置
    businessLineTree?: Array<any>; // 业务线树
}
const initState: StateType = { downLoadUrlMap: {}};
const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
export default createStoreWithMiddleware(reducer, initState as any);
