import { ProColumns } from "@ant-design/pro-table"
import { useDictEnumHooks } from "@hooks/index"
import { Cascader, CascaderProps, DatePicker, Input, Select, Tooltip } from "antd"
import React from "react"
import { SalesStageToolTip } from "."
import { Icon, NumberInput } from "@components/index"
import { floatNumReg, validatorMuch2Single } from "@utils/index"
import ProForm from '@ant-design/pro-form';
import { getTelecomArea } from "@api/index"
import { useSelector } from "react-redux"
import { StateType } from "@store/index"

export const salesStageColumns = [
    {
        title: '项目阶段',
        key: 'stage',
        dataIndex: 'stage'
    },
    {
        title: '里程碑标识',
        key: 'desc',
        dataIndex: 'desc',
        render: (text: any) => <div dangerouslySetInnerHTML={{__html: text}}></div>
    },
]

export const salesStageDataSource = [
    {
        stage: '10% 潜在商机',
        desc: `1、有明确的联系人 <br/>
               2、客户存在需求 <br/>
               3、有交流意向，可约拜访`
    },
    {
        stage: '20% 商机确认',
        desc: `
            1、已上门沟通交流 <br/>
            2、现网调研明确客户业务、IT架构等<br/>
            3、需求清晰明确<br/>
            4、清楚项目预算<br/>
            5、项目有时间节点要求<br/>
            6、对接到项目的负责人    
          `
    },
    {
        stage: '40% 方案沟通',
        desc: `
        1、提交并汇报对应的方案<br/>
        2、项目决策链路清晰，并接触到关键人<br/>
        3、客户对方案没有提出明确的异议，并存在支持方<br/>
        4、在客户方对接到两个以上的人员<br/>
        5、客户有测试意向，并投入一定的精力了解产品和方案
       `
    },
    {
        stage: '60% 商务谈判',
        desc: `
        1、确定项目报价<br/>
        2、明确存在的竞争对手，并了解其方案情况<br/>
        3、同项目决策人做过明确的工作交流<br/>
        4、明确客户方采购流程
        `
    },
    {
        stage: '80% 签约完成',
        desc: `
        1、完成招投标或者业务相关决策性动作<br/>
        2、拿到中标通知书<br/>
        3、签订合同<br/>
        4、客户方有明确的项目启动动作
        `
    },
    {
        stage: '100% 收入确认',
        desc: '完成收款或部分收款'
    },
    {
        stage: '持续运营阶段',
        desc: '客户由新拓落单转为存量后，进入到持续运营阶段'
    },
    {
        stage: '丢单',
        desc: '项目机会已明确被竞对拿下'
    },
    {
        stage: '挂起',
        desc: '项目遇到种种情况暂时搁置，后续会再重启'
    },
]

export const bussinessSonTableItem = (n: number = 1) => new Array(n).fill({
  followBusinessLineId: []
}).map((v, idx) => ({...v, id: `zqs-${idx}-${Date.now()}`}))

// 商机明细可编辑表格
export const bussinessSonTableColumns: (form: any) => ProColumns<any>[] = (form) => {
    const [, , request] = useDictEnumHooks({}, false)
    const { businessLineTree } = useSelector((state: StateType) => state)
    return [
        {
          title: <span><i style={{color: 'red'}}>*</i>关注业务线 </span>,
          dataIndex: 'followBusinessLineId',
          valueType: 'cascader',
          // params: {dictId: 19 },
          // request: businessLineRequest(request),
          fieldProps: { 
            options: businessLineTree,
            allowClear: true,
            // changeOnSelect: true,
            showSearch: true,
          },
          formItemProps: (_, {rowKey, dataIndex}) => {
            return {
              rules: [
                { required: true, message: '请选择关注业务线',}, 
                // { validator: validatorMuch2Single('业务线') }
              ]
            }
          }
        },
        // {
        //   title: <span>客户分层</span>,
        //   dataIndex: 'customerHierarchy',
        //   valueType: 'select',
        //   tooltip: '如果关注业务线 包含 阿里云，请必填!',
        //   params: {dictId: 289 },
        //   request,
        //   formItemProps: {
        //     rules: [
        //       {
        //         required: false,
        //         message: '此项是必填项',
        //       },
        //     ],
        //   },
        // },
        {
          title: <span><i style={{color: 'red'}}>*</i>项目阶段 <SalesStageToolTip trigger={<Icon type="icon-ziyuan" />} /></span>,
          dataIndex: 'salesStage',
          valueType: 'select',
          params: {dictId: 20 },
          request,
          formItemProps: {
            rules: [
              {
                required: true,
                message: '此项是必填项',
              }
            ],
          },
        },
        {
          title: <span><i style={{color: 'red'}}>*</i>订单类型</span>,
          dataIndex: 'orderType',
          valueType: 'select',
          params: {dictId: 254 },
          request,
          formItemProps: {
            rules: [
              {
                required: true,
                message: '此项是必填项',
              }
            ],
          },
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>下单方式</span>,
            dataIndex: 'orderMethod',
            valueType: 'select',
            params: {dictId: 279 },
            request,
            formItemProps: {
              rules: [
                {
                  required: true,
                  message: '此项是必填项',
                }
              ],
            },
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>预计签单日期</span>,
            dataIndex: 'estimatedSigningDate',
            valueType: 'date',
            formItemProps: {
              rules: [
                {
                  required: true,
                  message: '此项是必填项',
                }
              ],
            },
        },
        {
            title: <span>实际签单日期</span>,
            dataIndex: 'actualSigningDate',
            valueType: 'date',
            // formItemProps: {
            //   rules: [
            //     {
            //       required: true,
            //       message: '此项是必填项',
            //     }
            //   ],
            // },
            formItemProps: {
                rules: [
                  {
                    // required: true,
                    // whitespace: true,
                    // message: '此项是必填项',
                    validator: async (rules: any, val: string) => {
                      const {field} = rules
                      const fieldName = field.split('.')[0]
                      const {salesStage} = form.getFieldValue(fieldName) || {}
                      if (!salesStage) {
                        return Promise.reject('请先填写项目阶段')
                      } else if (salesStage === 36 && !val) { //当项目阶段为100%时此项必填
                        return Promise.reject('当项目阶段为100%时此项必填')
                      } else {
                        return Promise.resolve()
                      }
                    },
                  }
                ],
            },
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>意向Cash收入（元）</span>,
            dataIndex: 'estimatedCashIncome',
            formItemProps: {
              rules: [
                {
                  async validator(rule, value) {
                     if (floatNumReg.test((value+'').trim())) {
                      return Promise.resolve()
                    } else if (!value) {
                      return Promise.reject('此项是必填项')
                    } else {
                      return Promise.reject('请输入正确的金额数字')
                    }
                  },
                  // required: true,
                  // message: '此项是必填项',
                }
              ],
            },
            renderFormItem: (props) => <NumberInput style={{width: '100%'}} />
        },
        {
            title: <span><i style={{color: 'red'}}>*</i>意向差价收入（元）</span>,
            dataIndex: 'estimatedBetweenIncome',
            tooltip: '填写规则为：月度+季度佣金-给客户的返点。具体比例请咨询主管。',
            formItemProps: {
              rules: [
                {
                  async validator(rule, value) {
                    if (floatNumReg.test((value+'').trim())) {
                      return Promise.resolve()
                    } else if (!value) {
                      return Promise.reject('此项是必填项')
                    } else {
                      return Promise.reject('请输入正确的金额数字')
                    }
                  },
                  // required: true,
                  // message: '此项是必填项',
                }
              ],
            },
            renderFormItem: (props) => <NumberInput style={{width: '100%'}} />
        },
        {
            title: <span>落单Cash收入（元）</span>,
            dataIndex: 'singleCashReceipts',
            // initialValue: 0,
            formItemProps: {
              rules: [
                {
                  // required: true,
                  // whitespace: true,
                  // message: '此项是必填项',
                  validator: async (rules: any, val: string) => {
                    const {field} = rules
                    const fieldName = field.split('.')[0]
                    const {salesStage} = form.getFieldValue(fieldName) || {}
                    if (!salesStage) {
                      return Promise.reject('请先填写项目阶段')
                    } 
                    if (salesStage === 36) {
                      if (typeof val === 'number' || floatNumReg.test((val+'').trim())) {
                        return Promise.resolve()
                      } else if (!val) {
                        return Promise.reject('当项目阶段为100%时此项必填')
                      } else {
                        return Promise.reject('请输入正确的金额数字')
                      }
                    }
                    if (typeof val === 'number' || !val || ((val+'') && floatNumReg.test((val+'').trim()))) {
                      return Promise.resolve()
                    } else {
                      return Promise.reject('请输入正确的金额数字')
                    }
                  },
                }
              ],
            },
            renderFormItem: () => <NumberInput style={{width: '100%'}} />
        },
        {
            title: <span>落单差价收入（元）</span>,
            dataIndex: 'intentionSpreadIncome',
            // initialValue: 0,
            formItemProps: {
              rules: [
                {
                  // required: true,
                  // whitespace: true,
                  // message: '此项是必填项',
                  validator: async (rules: any, val: string) => {
                    const {field} = rules
                    const fieldName = field.split('.')[0]
                    const {salesStage} = form.getFieldValue(fieldName) || {}
                    if (!salesStage) {
                      return Promise.reject('请先填写项目阶段')
                    } 
                    if (salesStage === 36) {
                      if (typeof val === 'number' || floatNumReg.test((val+'').trim())) {
                        return Promise.resolve()
                      } else if (!val) {
                        return Promise.reject('当项目阶段为100%时此项必填')
                      } else {
                        return Promise.reject('请输入正确的金额数字')
                      }
                    }
                    if (typeof val === 'number' || !val || ((val+'') && floatNumReg.test((val+'').trim()))) {
                      return Promise.resolve()
                    } else {
                      return Promise.reject('请输入正确的金额数字')
                    }
                  },
                }
              ],
            },
            renderFormItem: (props) => <NumberInput style={{width: '100%'}} />
        },
        {
            title: <span>剩余意向Cash收入（元）</span>,
            dataIndex: 'residualIntentionCashIncome',
            tooltip: '剩余意向Cash收入=意向Cash收入-落单Cash收入',
            fieldProps:(_, {entry}: any) => {
              return {
                value: ((entry?.estimatedCashIncome||0) - (entry?.singleCashReceipts||0)) < 0 ? 0 : isNaN(((entry?.estimatedCashIncome||0) - (entry?.singleCashReceipts||0))) ? 0 : ((entry?.estimatedCashIncome||0) - (entry?.singleCashReceipts||0)).toFixed(2),
                disabled: true
              }
            },
            formItemProps: {
                rules: [
                  {
                      required: false,
                      message: '此项是必填项',
                  }
                ],
            },
        },
        {
            title: <span>剩余意向差价收入（元）</span>,
            dataIndex: 'residualIntentionSpreadIncome',
            fieldProps:(_, {entry}: any) => {
              return {
                value: ((entry?.estimatedBetweenIncome||0) - (entry?.intentionSpreadIncome||0)) < 0 ? 0 : isNaN((entry?.estimatedBetweenIncome||0) - (entry?.intentionSpreadIncome||0)) ? 0 : ((entry?.estimatedBetweenIncome||0) - (entry?.intentionSpreadIncome||0)).toFixed(2),
                disabled: true,
              }
            },
            tooltip: '剩余意向差价收入=意向差价收入-落单差价收入',
            formItemProps: {
                rules: [
                  {
                      required: false,
                      message: '此项是必填项',
                  }
                ],
            },
        },
        {
          title: '操作',
          valueType: 'option',
          width: 50,
          fixed:'right',
          render: () => [
            <a key="link">删除</a>,
          ],
        },
    ];
    
  }
  